import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled, alpha } from '@mui/material/styles';

import LoadingButton from "@mui/lab/LoadingButton";

import { CopyToClipboard } from "react-copy-to-clipboard";

import LogoutOutlinedIcon from "../assets/LogoutIcon.svg";
import SearchIcon from "../assets/SearchIcon.svg";
import CloseIcon from "../assets/Close-Icon.svg";
import CopyIcon from "../assets/CopyIcon.svg";

import { ObjectType } from "../types/tableTypes";

import {
  contentHeader,
  inputAdornMentStyle,
  label,
  renderMainComponent,
  textFieldStyle,
} from "./common/CustomModalStyle";
import CustomModal from "./common/CustomModal";

import { getCookie, removeAllCookie } from "../utils/Common";

import { RootContext } from "../context/RootContext";
import { logoutApp } from "../services/network";
import { Tooltip } from "@mui/material";
import TermSheetForm from "./termSheet/TermSheetForm";
import KDMWrapper from "./keyDealMetrics/KDMWrapper";

export default function Header({
  showEvaluatePageBtn = false,
  showEvaluateNewLoan = false,
  showSearch = false,
  showBorder = false,
  showSubmitLoanBtn = false,
  showSaveApplicationBtn = false,
  showBackToListBtn = false,
  // showLinkBtn = false,
  // showDownloadBtn = false,
  handleSaveApplication,
  setApplicationName,
  applicationName,
  isLoading,
  openSaveApplicationModal,
  setOpenSaveApplicationModal,
  evaluationID,
  searchText,
  setSearchText,
  handleSubmitApplication,
  handleSearch,
  disableSubmitApplication,
  disableSaveApplication,
  applicationError,
  setApplicationError,
  showCreateTermSheet,
  showKDMButton
}: {
  showEvaluatePageBtn?: boolean;
  showEvaluateNewLoan?: boolean;
  showSearch?: boolean;
  showBorder?: boolean;
  showSubmitLoanBtn?: boolean;
  showSaveApplicationBtn?: boolean;
  showBackToListBtn?: boolean;
  showLinkBtn?: boolean;
  showDownloadBtn?: boolean;
  validateForm?: () => boolean;
  handleSaveApplication?: (isTrue: boolean) => void;
  setApplicationName?: (value: string) => void;
  applicationName?: string;
  isLoading?: boolean;
  openSaveApplicationModal?: boolean;
  setOpenSaveApplicationModal?: (value: boolean) => void;
  evaluationID?: number;
  searchText?: string;
  setSearchText?: (str: string) => void;
  handleSubmitApplication?: () => void;
  handleSearch?: (e: React.FormEvent) => void;
  disableSubmitApplication?: boolean;
  disableSaveApplication?: boolean;
  applicationError?: string;
  setApplicationError?: (value: string) => void;
  showCreateTermSheet?: boolean;
  showKDMButton?: boolean;
}) {
  const { setDynamicLoader, dynamicConfig, setUserLoggedIn } = useContext(RootContext);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const refreshToken: string = getCookie("sizer_refresh_token") ?? "{}";

  const navigate = useNavigate();
  const { t } = useTranslation();

  const openList = () => {
    navigate("/list-view");
  };

  const openEvaluateLoan = () => {
    navigate("/evaluate-loan");
  };

  const handleBackToListPage = () => {
    navigate("/list-view");
  };

  const handleOpenSaveApplicationModal = () => {
    if (setOpenSaveApplicationModal) {
      setOpenSaveApplicationModal(true);
    }
  };

  const handleLogout = async () => {
    setDynamicLoader(true);
    try {
      const formBody: string[] = [];
      const logoutObject: ObjectType = {
        refresh_token: refreshToken,
        client_id: dynamicConfig?.ViteAppClientId,
      };

      Object.keys(logoutObject).forEach((property) => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(logoutObject[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
      });
      const formBodyString = formBody.join("&");
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      const url = `${dynamicConfig?.ViteAppRealms}/logout`;

      await logoutApp(url, formBodyString, headers);

      removeAllCookie();
      localStorage.removeItem("client_config");
      setUserLoggedIn(false);
    } catch (err) {
      console.error(err);
    } finally {
      setDynamicLoader(false);
    }
  };

  function renderSaveApplicationModal() {
    return (
      <>
        <Grid container style={renderMainComponent}>
          <Grid item>
            <span className="white-color">{t("saveApplication")}</span>
          </Grid>
          <Grid>
            <IconButton
              onClick={() =>
                setOpenSaveApplicationModal
                  ? setOpenSaveApplicationModal(false)
                  : null
              }
              data-testid="close-modal-button"
            >
              <img src={CloseIcon} />
            </IconButton>
          </Grid>
        </Grid>

        {/* Content */}
        <div className="p-24">
          <div className="mb-20">
            <span style={contentHeader}>{t("evaluationID")}: </span>
            <span>{evaluationID}</span>
          </div>

          <div className="mb-24">
            <label className="mb-8" style={label}>
              {t("applicationName")}
            </label>
            <sup className="label-required">*</sup>
            <TextField
              size="small"
              fullWidth
              data-testid="text-field-modal"
              value={applicationName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setApplicationName?.(e.target.value);
                if (setApplicationError) {
                  if (!e.target.value) {
                    setApplicationError(t("thisFieldIsRequired"));
                  } else {
                    setApplicationError("");
                  }
                }
              }}
              helperText={!applicationName ? applicationError : ""}
            />
          </div>

          <hr />
          <div className="mt-24 mb-24">
            <label className="mb-8" style={label}>
              {t("shareableLink")}
            </label>
            <TextField
              size="small"
              fullWidth
              style={textFieldStyle}
              value={window.location.href}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CopyToClipboard text={location.href}>
                      <IconButton sx={inputAdornMentStyle}>
                        <img src={CopyIcon} />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {/* <div style={{ display: "flex" }}>
            <IconButton>
              <img src={DownloadIcon} />
            </IconButton>
            <p>{t("downloadAsPdf")}</p>
          </div> */}
        </div>

        <Divider />
        <Box className="p-12">
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              setOpenSaveApplicationModal
                ? setOpenSaveApplicationModal(false)
                : null
            }
            className="mr-8"
            data-testid="cancel-button"
          >
            {t("cancel")}
          </Button>
          <LoadingButton
            data-testid="save-button"
            color="primary"
            variant="contained"
            onClick={() =>
              handleSaveApplication ? handleSaveApplication(false) : null
            }
            loading={isLoading}
          >
            {t("save")}
          </LoadingButton>
        </Box>
      </>
    );
  }

  useEffect(() => {
    setIsAuthenticated(Boolean(refreshToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header
      data-testid="header"
      style={
        showBorder
          ? { borderBottom: "1px solid #9FAAA9", height: "10vh" }
          : { borderBottom: "none"}
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <img style={{ width: '10vw' }} className="logo" src={dynamicConfig?.ViteAppTcLogo} alt="Logo" />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* {showDownloadBtn ? (
              <Grid item xs={2} sm={1} md={1} className="gray-border">
                <IconButton>
                  <img src={DownloadIcon} alt="download-icon" />
                </IconButton>
              </Grid>
            ) : null} */}
            {/* {showLinkBtn ? (
              <Grid item xs={2} sm={1} md={1} className="gray-border">
                <IconButton>
                  <img src={LinkIcon} alt="download-icon" />
                </IconButton>
              </Grid>
            ) : null} */}
            {showBackToListBtn ? (
              <Grid item className="gray-border">
                <Button
                  onClick={handleBackToListPage}
                  data-testid="back-button"
                >
                  {t("backToListPage")}
                </Button>
              </Grid>
            ) : null}
            {showKDMButton ? (
              <Grid item className="gray-border">
                <KDMWrapper />
              </Grid>
            ) : null}
            {showCreateTermSheet ? (
              <Grid item className="gray-border">
                <TermSheetForm evaluationID={evaluationID} />
              </Grid>
            ) : null}
            {showSaveApplicationBtn ? (
              <Grid item className="gray-border">
                <Button
                  className="mr-12"
                  variant="outlined"
                  onClick={handleOpenSaveApplicationModal}
                  data-testid="save-button"
                  disabled={disableSaveApplication}
                >
                  {t("saveApplication")}
                </Button>
              </Grid>
            ) : null}
            {showSubmitLoanBtn ? (
              <Grid item className="gray-border">
                <Tooltip title={disableSubmitApplication && dynamicConfig?.ViteAppKcRealm === "bplsizer" ? t("contactBD") : ""}>
                  <div>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      onClick={handleSubmitApplication}
                      loading={isLoading}
                      disabled={disableSubmitApplication}
                      data-testid="submit-loan-btn"
                    >
                      {t("submitApplication")}
                    </LoadingButton>
                  </div>
                </Tooltip>
              </Grid>
            ) : null}
            {showSearch ? (
              <Grid item className="gray-border">
                <form onSubmit={handleSearch}>
                  <OutlinedInput
                    size="small"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchText ? setSearchText(e.target.value) : null
                    }
                    endAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          type="button"
                          onClick={handleSearch}
                          data-testid="search-btn"
                        >
                          <img src={SearchIcon} alt="search-icon" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </form>
              </Grid>
            ) : null}
            {showEvaluateNewLoan ? (
              <Grid item>
                <Button
                  className="mr-12"
                  variant="contained"
                  onClick={openEvaluateLoan}
                  data-testid="evaluate-button"
                >
                  {t("evaluateNewLoan")}
                </Button>
              </Grid>
            ) : null}
            {showEvaluatePageBtn ? (
              <Grid item>
                <Button
                  className="mr-12"
                  variant="outlined"
                  onClick={openList}
                  data-testid="evaluations-page-button"
                >
                  {t("evaluationsPage")}
                </Button>
              </Grid>
            ) : null}
            {isAuthenticated ? (
              <Grid item>
                <Button
                  onClick={handleLogout}
                  variant="outlined"
                  data-testid="logout-button"
                >
                  {t("logout")}&ensp;
                  <img src={LogoutOutlinedIcon} alt="logout-icon" />
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {openSaveApplicationModal ? (
        <CustomModal
          open={openSaveApplicationModal}
          handleClose={setOpenSaveApplicationModal}
          children={renderSaveApplicationModal()}
          className={"saveApplication"}
        />
      ) : null}
    </header>
  );
}
