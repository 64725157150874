/* eslint-disable react-refresh/only-export-components */
import React from "react";
import moment from "moment";

import IconButton from "@mui/material/IconButton";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

import CustomButtonGroup from "../../components/common/CustomButtonGroup";
import CustomSelect from "../../components/common/CustomSelect";
import CustomInput from "../../components/common/CustomInput";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import CustomAutoComplete from "../../components/common/CustomAutoComplete";
import CustomRadio from "../../components/common/CustomRadio";
import CheckBox from "../../components/common/CheckBox";
import CustomChip from "../../components/common/CustomChip";

import ShareIcon from "../../assets/ShareIcon.svg";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccordionDownArrow from "../../assets/AccordionDownArrow.svg";

import { ObjectType } from "../../types/tableTypes";
import { LocationSuggestion, ZipCodeSuggestion } from "../../types/fieldTypes";

import { isFalsyButNotZero } from "../../utils/Validations";
import {
  evaluation,
  evaluationTestFieldTypes,
  getCookie,
  LoanSizerEnum,
  normalizeEvaluationResult,
  normalizeReqBody,
  normalizeResultLoanData,
} from "../../utils/Common";
import {
  getCriticalTests,
  getEligibleResult,
  getGuidlineEvaluationResult,
  getTemplateResponse,
  getTemplateResponseDSCR,
} from "../../services/network";
import { Tooltip } from "@mui/material";

export const sampleCondoEligibility = {
  monthlyHOAFee: null,
  hoaDuesAboveFifteen: null,
  hoaDuesAboveTwenty: null,
  reserveFundsForOneYear: null,
  reserveFundsForTwoYears: null,
  isProjectCompleted: null,
  subjectToAdditionalPhasing: null,
  soldPercentage: null,
  hoaUnderOwnerControl: null,
  simpleEstateOwnership: null,
  ownerOccupiedUnits: null,
  renterOccupiedUnits: null,
  renterOccupiedPercentage: null,
  individualOwnershipAboveTwentyFive: null,
  masterAssurancePolicy: null,
  projectHasMobileHomes: null,
  otherSourcesIncomeMoreThanTwenty: null,
  commercialPurposesAreaMoreThanForty: null,
  restrictOwnerToRent: null,
  documentsWithSEC: null,
  hoawithLitOrBankruptcy: null,
  ownerControlStartDate: null,
  indivdualOwnedUnits: null,
  hoawithLitOrBankruptcyDesc: null,
  restrictOwnerToRentOutDesc: null,
  condoProjectName: null,
  streetAddressLine1: null,
  noOfUnits: null,
  state: null,
  city: null,
  postalCode: null,
};

export const postGuidelineEvaluation: any = async (
  setDebouncedValue: any,
  tempLPData?: any
) => {
  setDebouncedValue(tempLPData);
};

const getForeignNationalValue = (value: string) => {
  if (value === "No") {
    return "N"
  }
  if (value === "Yes") {
    return "Y"
  }
  return value
}

export const getTestTabResultData = async (
  data: any,
  tempLoanData: any,
  publicAPI2Headers: ObjectType,
  isDSCR: boolean,
  tempLoanResult: ObjectType,
  loanTypeStr: string,
  fetchData: ObjectType,
  loanContextData: ObjectType,
  RootContextData: ObjectType,
  templateResponseData: ObjectType,
  setTemplateResponseData: any,
  dynamicConfig: any,
  templateLabels: any,
  assignedPartner?: string,
  tempCriticalTests?: any
) => {
  const criticalTestData = { ...(tempCriticalTests ?? {}) };
  const { DSCROrgPartnerMapping, dscrPartner } = RootContextData;
  const {
    setLeverageAndPricingData,
    setTestTabData,
    setFailedFinalRate,
    loanDetails,
    chipsData,
    setChipsData,
  } = loanContextData;
  let templateResponse = templateResponseData || {};

  try {
    const failTests: any = [];
    const passTests: any = [];
    let failedCountTests: number = 0;

    if (isDSCR) {
      const tempChipData: string[] = [...chipsData];
      const takeOutPartnerId =
        assignedPartner || loanDetails?.takeOutPartner || "";

      const partnerValues = Object.values(DSCROrgPartnerMapping);

      if (
        DSCROrgPartnerMapping[takeOutPartnerId] &&
        !tempChipData.includes(DSCROrgPartnerMapping[takeOutPartnerId])
      ) {
        const existingChip: string =
          tempChipData.find((chip: string) => partnerValues.includes(chip)) ||
          "";
        const indexOfChip = tempChipData.indexOf(existingChip);

        if (indexOfChip >= 0) {
          tempChipData.splice(
            indexOfChip,
            1,
            DSCROrgPartnerMapping[takeOutPartnerId]
          );
        } else {
          tempChipData.push(DSCROrgPartnerMapping[takeOutPartnerId]);
        }
        setChipsData(tempChipData);
      }

      const testData: any = {};
      for (let idx = 0; idx <= dscrPartner?.length - 1; idx++) {
        const currentData: any = dscrPartner[idx];
        if (data[currentData.id]) {
          testData[currentData.id] = data[currentData.id]
        }
      }

      const testKey = ["logicTests", "finalCalculation", "maxMinParameters"];
      await Promise.all(
        Object.keys(testData)?.map(async (key: string, idx: number) => {
          const tempFailedArr: ObjectType[] = [];
          const programKey = DSCROrgPartnerMapping[key] || `Un-Mapped ORG`;
          const currentPartnerData = data[key];
          let currentPartnerLoanResult =
            currentPartnerData?.[Object.keys(currentPartnerData)[0]];
          currentPartnerLoanResult =
            currentPartnerLoanResult?.[
            Object.keys(currentPartnerLoanResult)[0]
            ];

          const finalCalculation = currentPartnerLoanResult?.finalCalculation;
          const logicTests = currentPartnerLoanResult?.logicTests;
          const maxMinTests = currentPartnerLoanResult?.maxMinParameters;
          const tests = {
            ...finalCalculation,
            ...logicTests,
            ...maxMinTests,
          };
          const testNamesTemplateBody = Object.keys(tests).map(
            (testName: any) => `testNames.${testName}`
          );
          const finalCalculationTemplateKeys = Object.keys(
            currentPartnerLoanResult?.finalCalculation || {}
          ).map(
            (testName: any) =>
              `loanResult.finalCalculation.${testName}.message.FAIL`
          );
          const logicTestsTemplateKeys = Object.keys(
            currentPartnerLoanResult?.logicTests || {}
          ).map(
            (testName: any) =>
              `loanResult.logicTests.${testName}.message.FAIL`
          );
          const maxMinTestsTemplateKeys = Object.keys(
            currentPartnerLoanResult?.maxMinParameters || {}
          ).map(
            (testName: any) =>
              `loanResult.maxMinParameters.${testName}.message.FAIL`
          );

          const reqBody = {
            templateKeys: [
              ...testNamesTemplateBody,
              ...finalCalculationTemplateKeys,
              ...logicTestsTemplateKeys,
              ...maxMinTestsTemplateKeys,
            ],
          };
          const partyId = getCookie("partyId") || dynamicConfig?.partyId;
          const orgId = getCookie("clientId") || dynamicConfig?.clientId;

          const tpId = `${key}`;
          // const templateUrl = `public-api/template/fetch?locale=NA&countryCode=NA&partialProcess=true&tpId=${key}`;
          // const failedURL = getURL(templateUrl);

          if (
            !templateResponse ||
            !templateResponse[key] ||
            Object.keys(templateResponse[key]).length === 0
          ) {
            const templateResponseDSCR = await getTemplateResponseDSCR(
              tpId,
              orgId,
              reqBody
            );
            templateResponse[key] = templateResponseDSCR;
          }

          Object.keys(tests)?.forEach((testName: string) => {
            if (
              !["sectionEligibility", "overallLoanEligibility", "criticalTestLoanEligibility"].includes(
                testName
              )
            ) {
              const testType = logicTests[testName]
                ? `logicTests`
                : maxMinTests[testName]
                  ? `maxMinParameters`
                  : `finalCalculation`;
              const testFailReason = evaluation(
                templateResponse[key]?.data?.[
                `loanResult.${testType}.${testName}.message.FAIL`
                ],
                {
                  ...currentPartnerLoanResult,
                  loanResult: {
                    ...currentPartnerLoanResult.loanResult,
                    afterAdjustmentMaxLTVAsIs:
                      currentPartnerLoanResult?.[
                      "toorakProceeds"]?.["afterAdjustmentMaxLTVAsIs"
                      ] ??
                      currentPartnerLoanResult?.["toorakProceeds"]?.["toorakAdjustedAsIsLtv"],
                    toorakAdjustedAsIsLtv: currentPartnerLoanResult?.[
                      "toorakProceeds"]?.["afterAdjustmentMaxLTVAsIs"
                    ] ??
                      currentPartnerLoanResult?.["toorakProceeds"]?.["toorakAdjustedAsIsLtv"],
                    dscr: currentPartnerLoanResult?.["loanEconomics"]?.["dscr"]
                  }
                },
                templateLabels
              );
              const testData = {
                title:
                  eval(
                    templateResponse[key]?.data?.[`testNames.${testName}`]
                  ) ?? testName,
                status: "Failed",
                subText: testFailReason,
                isCriticalTest: criticalTestData?.[key]?.includes(
                  testName
                ) ?? false,
              };

              switch (tests[testName]) {
                case "FAIL":
                  tempFailedArr.push(testData);
                  if (!assignedPartner) {
                    failedCountTests += 1;
                  }
                  break;
                case "PASS":
                  passTests.push(testData);
                  break;
              }
            }
          });

          // if (tempFailedArr.length)
          failTests[idx] = { [programKey]: tempFailedArr };
          // failTests.push({ [programKey]: tempFailedArr });
        })
      );
      if (assignedPartner) {
        const currentPartnerData = data?.[assignedPartner];
        const { loanResult } = currentPartnerData;
        const testsData = loanResult?.[Object.keys(loanResult)[0]];
        Object.keys(testsData)?.forEach((testName: string) => {
          if (testKey.includes(testName)) {
            const failedTests = Object.keys(testsData[testName]).filter((key: any) => testsData?.[testName]?.[key] === "FAIL" && !["sectionEligibility", "overallLoanEligibility", "criticalTestLoanEligibility"].includes(key));
            failedCountTests += failedTests.length;
          }
        })
      }
    } else {
      const testKey = ["guidelineTest"];
      const loanKey = Object.keys(data?.loanResult)[0];
      const guidelineTests = data?.loanResult?.[loanKey]?.guidelineTest;
      const loanEconomicsTests = data?.loanResult?.[loanKey]?.loanEconomics ?? {}
      const tests = { ...guidelineTests, ...loanEconomicsTests };
      const testNamesTemplateBody = Object.keys(tests).map(
        (testName: any) => `testNames.${testName}`
      );
      const guidelineTestTemplateKeys = Object.keys(
        data?.loanResult?.[loanKey]?.guidelineTest
      ).map(
        (testName: any) => `loanResult.guidelineTest.${testName}.message.FAIL`
      );

      const loanEconomicsTestTemplateKeys = Object.keys(
        data?.loanResult?.[loanKey]?.loanEconomics ?? {}
      ).map(
        (testName: any) => `loanResult.loanEconomics.${testName}.message.FAIL`
      );

      const reqBody = {
        templateKeys: [...testNamesTemplateBody, ...guidelineTestTemplateKeys, ...loanEconomicsTestTemplateKeys],
      };
      const partyId = getCookie("partyId") || dynamicConfig?.partyId;

      if (!templateResponse || Object.keys(templateResponse).length === 0) {
        templateResponse = await getTemplateResponse(
          partyId,
          reqBody,
          publicAPI2Headers
        );
      }

      Object.keys(tests)?.forEach((testName: string) => {
        if (tests[testName] && testName !== "sectionEligibility") {
          const testType = guidelineTests[testName]
            ? `guidelineTest`
            : loanEconomicsTests[testName]
              ? `loanEconomics`
              : ``;
          const testFailReason = evaluation(
            templateResponse?.data?.[
            `loanResult.${testType}.${testName}.message.FAIL`
            ] || evaluationTestFieldTypes[testType][testName]?.readableName,
            {
              ...data?.loanResult?.[loanKey],
              loanResult: {
                isForeignNational: getForeignNationalValue(loanDetails?.borrowerInformation?.borrowerGuarantor ?? "") ?? null,
                customerType: data?.loanResult?.[loanKey]?.loanCharacterisations?.customerType ?? "",
              },
              loan: {
                loanSizerType: fetchData?.loanType === "Ground Up" ? LoanSizerEnum.groundUp : LoanSizerEnum.oneFourFamily,
              }
            },
            templateLabels
          );
          const testData = {
            title:
              eval(templateResponse?.data?.[`testNames.${testName}`]) ??
              testName,
            status: "Failed",
            subText: testFailReason,
          };

          switch (tests[testName]) {
            case "FAIL":
              failTests.push(testData);
              failedCountTests += 1;
              break;
            case "PASS":
              passTests.push(testData);
              break;
          }
        }
      });
    }

    const getResult: any = normalizeEvaluationResult(
      tempLoanResult,
      loanTypeStr,
      tempLoanData,
      fetchData,
      failedCountTests
    );

    if (
      !templateResponseData ||
      Object.keys(templateResponseData).length === 0
    ) {
      setTemplateResponseData(templateResponse);
    }
    setLeverageAndPricingData(getResult || {});
    setTestTabData(failTests);
    setFailedFinalRate(
      !(
        getResult?.leverageCards?.finalRate ||
        getResult?.leverageCards?.finalPrice
      )
    );
    setFailedFinalRate(
      !(
        getResult?.leverageCards?.finalRate ||
        getResult?.leverageCards?.finalPrice
      )
    );
    return failTests;
  } catch (err) {
    console.error(err);
  }
};

const postalOnChange = async (
  event: React.ChangeEvent<HTMLInputElement>,
  newValue: ZipCodeSuggestion | null,
  childIdx: number | undefined,
  field: ObjectType,
  translation: any,
  loanContextData: ObjectType
) => {
  const {
    addressInputValue,
    propFormErrors,
    propertyDetails,
    setPropertyDetails,
    loanFormData,
    setLoanFormData,
    zipCodeOptions,
    setZipCodeOptions,
    setPropFormErrors,
    setDebouncedValue,
  } = loanContextData;
  setZipCodeOptions(newValue ? [newValue, ...zipCodeOptions] : zipCodeOptions);
  const tempPropFormErrors = [...propFormErrors];
  if (newValue) {
    const { city, state_abbreviation, zipCode } = newValue;
    const tempFormData = propertyDetails?.map(
      (propDetail: ObjectType, idx: number) => {
        if (idx === childIdx) {
          tempPropFormErrors[idx] = {
            ...tempPropFormErrors[idx],
            ["propertyLocation"]: {
              ...tempPropFormErrors[idx]?.["propertyLocation"],
              ["address"]:
                translation(field?.getFieldErrors(addressInputValue)) ?? "",
              ["city"]: translation(field?.getFieldErrors(city)) ?? "",
              ["state"]:
                translation(field?.getFieldErrors(state_abbreviation)) ?? "",
              ["postalCode"]: translation(field?.getFieldErrors(zipCode)) ?? "",
            },
          };
          const prevCity = propDetail?.propertyLocation?.city;
          const prevState = propDetail?.propertyLocation?.state;

          return {
            ...propDetail,
            propertyLocation: {
              ...propDetail.propertyLocation,
              ["address"]: `${addressInputValue
                ?.replace(prevCity, city)
                ?.replace(prevState, state_abbreviation)}`,
              ["city"]: city,
              ["state"]: state_abbreviation,
              ["postalCode"]: zipCode,
            },
          };
        }
        return propDetail;
      }
    );
    setPropFormErrors(tempPropFormErrors);
    setPropertyDetails(tempFormData);
    setLoanFormData({ ...loanFormData, propertyDetails: tempFormData });
  }
  postGuidelineEvaluation(setDebouncedValue, newValue);
};

const addressOnChange = async (
  event: React.ChangeEvent<HTMLInputElement>,
  newValue: LocationSuggestion | null,
  childIdx: number | undefined,
  field: ObjectType,
  translation: any,
  loanContextData: ObjectType
) => {
  const {
    propFormErrors,
    propertyDetails,
    setPropertyDetails,
    loanFormData,
    setLoanFormData,
    addressOptions,
    setAddressOptions,
    setPropFormErrors,
    setDebouncedValue,
  } = loanContextData;
  setAddressOptions(newValue ? [newValue, ...addressOptions] : addressOptions);
  const tempPropFormErrors = [...propFormErrors];
  if (newValue) {
    const { street_line, city, state, zipcode } = newValue;
    const tempFormData = propertyDetails?.map(
      (propDetail: ObjectType, idx: number) => {
        if (idx === childIdx) {
          tempPropFormErrors[idx] = {
            ...tempPropFormErrors[idx],
            ["propertyLocation"]: {
              ...tempPropFormErrors[idx]?.["propertyLocation"],
              ["address"]:
                translation(
                  field?.getFieldErrors(`${street_line} ${city} ${state}`)
                ) ?? "",
              ["city"]: translation(field?.getFieldErrors(city)) ?? "",
              ["state"]: translation(field?.getFieldErrors(state)) ?? "",
              ["postalCode"]: translation(field?.getFieldErrors(zipcode)) ?? "",
            },
          };
          return {
            ...propDetail,
            propertyLocation: {
              ...propDetail.propertyLocation,
              ["address"]: `${street_line} ${city} ${state}`,
              ["city"]: city,
              ["state"]: state,
              ["postalCode"]: zipcode,
            },
          };
        }
        return propDetail;
      }
    );

    setPropFormErrors(tempPropFormErrors);
    setPropertyDetails(tempFormData);
    setLoanFormData({ ...loanFormData, propertyDetails: tempFormData });
  }
  postGuidelineEvaluation(setDebouncedValue, newValue);
};

const addressOnInputChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: string,
  translation: any,
  loanContextData: ObjectType,
  childIdx?: number | undefined,
  field?: ObjectType | undefined
) => {
  const {
    propertyDetails,
    setPropertyDetails,
    loanFormData,
    setLoanFormData,
    addressInputValue,
    setAddressInputValue,
    propFormErrors,
    setPropFormErrors,
  } = loanContextData;
  const tempPropFormErrors = [...propFormErrors];
  const tempFormData = propertyDetails?.map(
    (propDetail: ObjectType, idx: number) => {
      if (idx === childIdx) {
        tempPropFormErrors[idx] = {
          ...tempPropFormErrors[idx],
          ["propertyLocation"]: {
            ...tempPropFormErrors[idx]?.["propertyLocation"],
            ["address"]: translation(field?.getFieldErrors(value) ?? "") ?? "",
          },
        };
        return {
          ...propDetail,
          propertyLocation: {
            ...propDetail.propertyLocation,
            ["address"]: value,
          },
        };
      }
      return propDetail;
    }
  );

  setPropFormErrors(tempPropFormErrors);
  setPropertyDetails(tempFormData);
  setLoanFormData({ ...loanFormData, propertyDetails: tempFormData });
  setAddressInputValue(value);
};

const postalCodeOnInputChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: string,
  translation: any,
  loanContextData: ObjectType,
  childIdx: number | undefined
) => {
  const {
    propertyDetails,
    setPropertyDetails,
    loanFormData,
    setLoanFormData,
    zipCodeInputValue,
    setZipCodeInputValue,
  } = loanContextData;
  if (value.length > 7) return;
  const tempFormData = propertyDetails?.map(
    (propDetail: ObjectType, idx: number) => {
      if (idx === childIdx) {
        return {
          ...propDetail,
          propertyLocation: {
            ...propDetail.propertyLocation,
            ["postalCode"]: value,
          },
        };
      }
      return propDetail;
    }
  );
  setPropertyDetails(tempFormData);
  setLoanFormData({ ...loanFormData, propertyDetails: tempFormData });
  setZipCodeInputValue(value);
};

const getAddressOptionLabel = (option: LocationSuggestion) => {
  let value;
  if (typeof option === "string") {
    value = option;
  } else if (option.street_line) {
    value = `${option.street_line} ${option.city} ${option.state} ${option.zipcode}`;
  } else {
    value = "";
  }
  return value;
};

const getPostalOptionLabel = (option: any) => {
  let value;
  if (typeof option === "string") {
    value = option;
  } else if (option.city) {
    value = `${option.city} ${option.state_abbreviation} ${option.zipCode}`;
  } else {
    value = "";
  }
  return value;
};

export function handleCondoEligibilityModalChange(
  value: any,
  field: any,
  subComponentLabel: string,
  translation: any,
  loanContextData: ObjectType
) {
  const {
    condoEligibilityConfig,
    condoEligibilityFormData,
    setCondoEligibilityConfig,
    setCondoEligibilityFormData,
  } = loanContextData;
  let tempConfig = { ...condoEligibilityConfig };

  tempConfig = {
    ...tempConfig,
    fields: {
      ...tempConfig?.fields,
      fee: tempConfig?.fields?.fee?.map((feeEle: any) => {
        if (
          feeEle.label === "ownerControlStartDate" &&
          field.label === "hoaUnderOwnerControl"
        ) {
          if (value === "Yes") {
            feeEle.isVisible = true;
          } else {
            feeEle.isVisible = false;
          }
        }
        if (
          feeEle.label === "indivdualOwnedUnits" &&
          field.label === "individualOwnershipAboveTwentyFive"
        ) {
          if (value === "Yes") {
            feeEle.isVisible = true;
          } else {
            feeEle.isVisible = false;
          }
        }
        if (
          feeEle.label === "hoawithLitOrBankruptcyDesc" &&
          field.label === "hoawithLitOrBankruptcy"
        ) {
          if (value === "Yes") {
            feeEle.isVisible = true;
          } else {
            feeEle.isVisible = false;
          }
        }
        if (
          feeEle.label === "restrictOwnerToRent" &&
          field.label === "restrictOwnerToRentOutDesc"
        ) {
          if (value === "Yes") {
            feeEle.isVisible = true;
          } else {
            feeEle.isVisible = false;
          }
        }
        return { ...feeEle };
      }),
    },
  };

  setCondoEligibilityConfig(tempConfig);
  setCondoEligibilityFormData({
    ...condoEligibilityFormData,
    [field.label]: value,
  });
}

export function getTitleInfo(
  field: ObjectType,
  isAutoComplete: boolean,
  loanType: string | undefined,
  translation: any,
  loanContextData: ObjectType
) {
  const {
    expandLeverageAndTests,
    defaultSelectedDataView,
    setCondoEligibility,
  } = loanContextData;

  let className = "div-space-between mb-14 w-80";
  if (isAutoComplete) {
    className = "div-space-between mb-0 w-80";
  }
  if (field.type() === "checkBox") {
    className = "div-space-between mb-0";
  }

  const label = `${translation(field?.displayLabel || field?.fieldLabel || field?.label)}
    ${field?.isMedian ? ` (${translation("median")})` : ""}
    ${field?.ifApplicable ? ` ${translation("ifApplicable")}` : ""}`;

  const title = translation(field?.title) || label;

  return (
    <div className={className}>
      <div
        className={`truncate-words ${expandLeverageAndTests ? "" : "ml-12"} d-flex`}
        style={{ marginTop: expandLeverageAndTests ? "15px" : "", alignItems: "center" }}
      >
        <div className="question-labels" title={title}>
          {label}
          {field.required &&
            !field?.isAutoCalculated(loanType, defaultSelectedDataView) &&
            field.id !== "inPlaceLeaseRentMonthly" ? (
            <sup className="label-required">*</sup>
          ) : null}
        </div>
        {
          field?.info ? <Tooltip title={translation(field.info)}><InfoOutlinedIcon fontSize="small" style={{ height: "14px" }} /></Tooltip> : null
        }
      </div>
      {field.required &&
        !field?.isAutoCalculated(loanType, defaultSelectedDataView) &&
        field.id === "inPlaceLeaseRentMonthly" ? (
        <sup className="label-required">*</sup>
      ) : null}
      {field.checkEligibility ? (
        <div
          className="div-start align-center cursor-ptr"
          onClick={() => setCondoEligibility(true)}
        >
          <a>{translation(field.label)}</a>
          &ensp;
          <img src={ShareIcon} alt="share-icon.svg" />
        </div>
      ) : null}
      {field?.isAutoCalculated &&
        field?.isAutoCalculated(loanType, defaultSelectedDataView) &&
        !expandLeverageAndTests ? (
        <div>
          <CustomChip
            size="small"
            label={translation("autoCalculated")}
            variant="outlined"
            className="chipData"
          />
        </div>
      ) : null}
    </div>
  );
}

const updateVisibility = (
  field: ObjectType,
  value: any,
  tempPropertyDetailsConfig: any,
  propertyDetails: any,
  chipsData: string[],
  childIdx?: number
) => {
  return tempPropertyDetailsConfig?.map((propField: any, propIdx: number) => {
    if (propIdx === childIdx) {
      return {
        ...propField,
        fields: propField?.fields?.map((subcomponent: ObjectType) => {
          if (subcomponent.label === "propertyInformation") {
            return {
              ...subcomponent,
              fields: subcomponent.fields.map((indField: ObjectType) => {
                if (
                  field.label === "propertyType" &&
                  indField.label === "numberOfUnits"
                ) {
                  if (["Condo", "SFR"].includes(value)) {
                    return {
                      ...indField,
                      isVisible: (value = false),
                    };
                  } else {
                    return {
                      ...indField,
                      isVisible: (value = true),
                    };
                  }
                } else if (
                  ["numberOfUnits", "propertyType"].includes(field.label) &&
                  ["numberOfUnits", "propertyType"].includes(indField.label) &&
                  (propertyDetails[childIdx]?.["propertyInformation"]?.["propertyType"] === "2-4 Unit")
                ) {
                  indField.isPopUpMessage = +value <= 1;
                } else {
                  indField.isPopUpMessage = false;
                }
                return indField;
              }),
            };
          }
          return subcomponent;
        }),
      };
    }
    return propField;
  });
};

function getDynamicFields(
  value: any,
  field: any,
  subComponentLabel: string,
  loanContextData: ObjectType,
  component?: ObjectType | undefined,
  childIdx?: number | undefined,
  unitIdx?: number,
  chipsData?: any
) {
  const {
    loanDetailsConfig,
    propertyDetailsConfig,
    borrowerInformationConfig,
    autoCalculatedLoanResults,
    loanDetails,
    propertyDetails,
  } = loanContextData;

  let tempLoanDetailsConfig = { ...loanDetailsConfig };
  let tempPropertyDetailsConfig = [...propertyDetailsConfig];
  let tempBorrowerInformationConfig = { ...borrowerInformationConfig };

  if (["loanDetails", "borrowerDetails"].includes(component?.label)) {
    const dynamicLoanDetailsFields = [
      "borrowerGuarantor",
      "loanPurpose",
      "interestOnlyFlag",
      "immigrationStatus",
      "pledgeOfEquity",
      "crossCollaterlization",
      "predominantState",
      "numberOfProperties",
      "includeOutOfPocketBudgetARLTV",
      "totalRehabBudget",
      "totalBudget",
      "financedBudget",
      "numberOfUnits",
      "propertyType",
      "firstTimeHomeBuyer",
      "borrowerType"
    ];
    if (dynamicLoanDetailsFields.includes(field.label)) {
      tempBorrowerInformationConfig = {
        ...tempBorrowerInformationConfig,
        fields: tempBorrowerInformationConfig?.fields.map(
          (subComponent: ObjectType) => {
            const subComponentFields = subComponent.fields.map(
              (fieldObj: ObjectType) => {
                if (subComponent.label === subComponentLabel) {
                  if (
                    fieldObj.label === "immigrationStatus" &&
                    field.label === "borrowerGuarantor"
                  ) {
                    fieldObj.minimumDataView = value === "Yes";
                    fieldObj.isVisible = value === "Yes";
                  }
                  if (
                    fieldObj.label === "firstTimeHomeBuyer" &&
                    field.label === "borrowerType"
                  ) {
                    fieldObj.minimumDataView = value === "Individual";
                    fieldObj.isVisible = value === "Individual";
                  }
                  if (
                    ["creditScoreActual", "originalCreditScore"].includes(
                      fieldObj.label
                    ) &&
                    field.label === "immigrationStatus"
                  ) {
                    fieldObj.required = value !== "Foriegn National";
                  }
                  if (
                    ["creditScoreActual", "originalCreditScore"].includes(
                      fieldObj.label
                    ) &&
                    field.label === "borrowerGuarantor"
                  ) {
                    const immigrationStatusObj = subComponent.fields.find(
                      (ele: ObjectType) => ele.label === "immigrationStatus"
                    );
                    fieldObj.required = immigrationStatusObj?.isVisible
                      ? value !== "No"
                      : true;
                  }
                  return fieldObj;
                }
                return fieldObj;
              }
            );
            return {
              ...subComponent,
              fields: subComponentFields,
            };
          }
        ),
      };

      tempLoanDetailsConfig = {
        ...tempLoanDetailsConfig,
        fields: tempLoanDetailsConfig?.fields.map(
          (subComponent: ObjectType) => {
            const subComponentFields = subComponent.fields.map(
              (fieldObj: ObjectType) => {
                if (subComponent.label === subComponentLabel) {
                  if (
                    fieldObj.label === "includeOutOfPocketBudgetARLTV" &&
                    [
                      "totalRehabBudget",
                      "financedBudget",
                      "totalBudget",
                    ].includes(field.label)
                  ) {
                    const value1 = ["totalRehabBudget", "totalBudget"].includes(
                      field.label
                    )
                      ? +value
                      : chipsData.includes("Ground Up")
                        ? +autoCalculatedLoanResults?.loanEconomics?.totalBudget
                        : +loanDetails?.loanEconomics?.totalRehabBudget || "";
                    const value2 =
                      field.label === "financedBudget"
                        ? +value
                        : +loanDetails?.loanEconomics?.financedBudget || "";
                    fieldObj.minimumDataView = value1 !== value2;
                    fieldObj.isVisible = value1 !== value2;
                  }
                  if (
                    fieldObj.label === "crossCollaterlization" &&
                    field.label === "numberOfProperties"
                  ) {
                    fieldObj.minimumDataView = value > 1;
                    fieldObj.isVisible = value > 1;
                  }
                  if (
                    fieldObj.label === "immigrationStatus" &&
                    field.label === "borrowerGuarantor"
                  ) {
                    fieldObj.minimumDataView = value === "Yes";
                    fieldObj.isVisible = value === "Yes";
                  }
                  if (
                    fieldObj.label === "firstTimeHomeBuyer" &&
                    field.label === "borrowerType"
                  ) {
                    fieldObj.minimumDataView = value === "Individual";
                    fieldObj.isVisible = value === "Individual";
                  }
                  if (
                    fieldObj.label === "ioPeriod" &&
                    field.label === "interestOnlyFlag"
                  ) {
                    fieldObj.minimumDataView = value === "Yes";
                    fieldObj.isVisible = value === "Yes";
                  }
                  if (
                    ["predominantState", "predominantPropertyType"].includes(
                      fieldObj.label
                    ) &&
                    field.label === "crossCollaterlization"
                  ) {
                    fieldObj.minimumDataView = [value, loanDetails?.loanInformation?.crossCollaterlization].includes("Y");
                    fieldObj.isVisible = [value, loanDetails?.loanInformation?.crossCollaterlization].includes("Y");
                  }
                  if (
                    ["predominantState", "predominantPropertyType"].includes(
                      fieldObj.label
                    ) &&
                    field.label === "numberOfProperties"
                  ) {
                    fieldObj.minimumDataView = value > 1 && loanDetails?.loanInformation?.crossCollaterlization === "Y";
                    fieldObj.isVisible = value > 1 && loanDetails?.loanInformation?.crossCollaterlization === "Y";
                  }
                  if (
                    ["creditScoreActual", "originalCreditScore"].includes(
                      fieldObj.label
                    ) &&
                    field.label === "immigrationStatus"
                  ) {
                    fieldObj.required = value !== "Foriegn National";
                  }
                  if (
                    ["creditScoreActual", "originalCreditScore"].includes(
                      fieldObj.label
                    ) &&
                    field.label === "borrowerGuarantor"
                  ) {
                    const immigrationStatusObj = subComponent.fields.find(
                      (ele: ObjectType) => ele.label === "immigrationStatus"
                    );
                    fieldObj.required = immigrationStatusObj?.isVisible
                      ? value !== "No"
                      : true;
                  }
                  if (
                    ["totalRehabBudget", "totalBudget"].includes(
                      fieldObj.label
                    ) &&
                    ["totalRehabBudget", "totalBudget"].includes(field.label)
                  ) {
                    if (
                      chipsData.includes("Stabilized") &&
                      !chipsData.includes("Multifamily/Mixed Use") &&
                      +value > 0
                    ) {
                      fieldObj.isPopUpMessage = true;
                    } else {
                      delete fieldObj.isPopUpMessage;
                    }
                  }
                  if (
                    fieldObj.label === "financedBudget" &&
                    [
                      "totalRehabBudget",
                      "financedBudget",
                      "totalBudget",
                    ].includes(field.label)
                  ) {
                    const value1 =
                      ["totalRehabBudget", "totalBudget"].includes(field.label)
                        ? +value
                        : chipsData.includes("Ground Up") ? +autoCalculatedLoanResults?.loanEconomics?.totalBudget : (+autoCalculatedLoanResults?.loanEconomics?.totalRehabBudget || +loanDetails?.loanEconomics?.totalRehabBudget) ?? "";
                    const value2 =
                      field.label === "financedBudget"
                        ? +value
                        : +loanDetails?.loanEconomics?.financedBudget || "";
                    fieldObj.isPopUpMessage = value1 !== value2;
                  }
                  return fieldObj;
                }

                if (
                  fieldObj.label === "requestedLTPP" &&
                  field.label === "loanPurpose"
                ) {
                  fieldObj.minimumDataView = value === "Purchase";
                  fieldObj.isVisible = value === "Purchase";
                }
                return fieldObj;
              }
            );
            return {
              ...subComponent,
              fields: subComponentFields,
            };
          }
        ),
      };
    } else if (field.label === "exitStrategy") {
      tempPropertyDetailsConfig = tempPropertyDetailsConfig?.map(
        (propField) => {
          return {
            ...propField,
            fields: propField?.fields?.map((subcomponent: ObjectType) => {
              if (subcomponent.label === "propertyEconomics") {
                return {
                  ...subcomponent,
                  fields: subcomponent.fields.map((indField: ObjectType) => {
                    if (
                      indField.label === "estimatedRentPerYearUponCompletion" &&
                      field.label === "exitStrategy"
                    ) {
                      return {
                        ...indField,
                        minimumDataView: value === "Rehab to Hold/Rent",
                        isVisible: value === "Rehab to Hold/Rent",
                      };
                    }
                    if (indField.label === "grossPotentialRentMonthly" &&
                      field.label === "exitStrategy"
                    ) {
                      return {
                        ...indField,
                        minimumDataView: value === "Build to Rent",
                        isVisible: value === "Build to Rent",
                      }
                    }
                    else {
                      return indField;
                    }
                  }),
                };
              } else {
                return subcomponent;
              }
            }),
          };
        }
      );
    }
  } else {
    if (field.label === "currentLeaseTermsInMonths") {
      tempPropertyDetailsConfig = tempPropertyDetailsConfig?.map(
        (propConfig: ObjectType, propIdx: number) => {
          if (propIdx === childIdx) {
            const tempPropConfig = propConfig?.fields.map(
              (propField: any, propFieldIdx: number) => {
                if (Array.isArray(propField)) {
                  const tempPropField = propField.map(
                    (unitInfo: ObjectType, unitInfoIdx: number) => {
                      if (
                        unitInfoIdx === unitIdx &&
                        unitInfo.label === subComponentLabel
                      ) {
                        const unitInfoArr = unitInfo.fields.map(
                          (unitField: ObjectType) => {
                            const tempUnitField = { ...unitField };
                            if (field.label === "currentLeaseTermsInMonths") {
                              const isZero = +value === 0;
                              const isInRange1to5 = +value >= 1 && +value <= 5;
                              const dynamicFields = [
                                "currentUnitUsage",
                                "rentalCharacterization",
                                "monthlyMarketRent",
                                "monthlyUnitRent",
                                "tenantOccupancy",
                                "operatingHistory",
                              ];
                              dynamicFields.forEach((fieldStr) => {
                                if (fieldStr === tempUnitField.label) {
                                  if (
                                    (isZero &&
                                      [
                                        "currentUnitUsage",
                                        "rentalCharacterization",
                                        "monthlyMarketRent",
                                        "monthlyUnitRent",
                                      ].includes(fieldStr)) ||
                                    (isInRange1to5 &&
                                      [
                                        "rentalCharacterization",
                                        "monthlyMarketRent",
                                        "monthlyUnitRent",
                                        "tenantOccupancy",
                                        "operatingHistory",
                                      ].includes(fieldStr)) ||
                                    (+value > 5 &&
                                      [
                                        "rentalCharacterization",
                                        "monthlyMarketRent",
                                        "monthlyUnitRent",
                                      ].includes(fieldStr))
                                  ) {
                                    tempUnitField.minimumDataView = true;
                                  } else {
                                    tempUnitField.minimumDataView = false;
                                  }
                                  tempUnitField.isVisible =
                                    tempUnitField.minimumDataView;
                                }
                              });
                              return tempUnitField;
                            }
                            return unitField;
                          }
                        );
                        return {
                          ...unitInfo,
                          fields: unitInfoArr,
                        };
                      }
                      return { ...unitInfo };
                    }
                  );
                  return tempPropField;
                }
                return propField;
              }
            );
            return { ...propConfig, fields: tempPropConfig };
          }
          return propConfig;
        }
      );
    }
    if (["propertyType", "numberOfUnits"].includes(field.label)) {
      tempPropertyDetailsConfig = updateVisibility(
        field,
        value,
        tempPropertyDetailsConfig,
        propertyDetails,
        chipsData,
        childIdx
      );
    }
  }
  return {
    tempLoanDetailsConfig,
    tempPropertyDetailsConfig,
    tempBorrowerInformationConfig,
  };
}

function updateChipsData(fetchData: any, chipsData: any, setChipsData: any, newPropertyType?: string,) {
  const tempFetchData = { ...fetchData };
  const indexOfMostRestrictiveProperty = tempFetchData?.autoCalculatedLoanResults?.loanCharacterisation?.indexOfMostRestrictiveProperty ?? 0
  const propertyType = tempFetchData?.propertyDetails?.[indexOfMostRestrictiveProperty]?.propertyInformation?.propertyType
  const newChipsData: string[] = chipsData.map((chip: string) => {
    if (chip === propertyType) {
      // if (chip === tempFetchData?.propertyType) {
      if (newPropertyType === "SFR") {
        return "Single Family/Detached";
      }
      return newPropertyType;
    }
    return chip;
  });
  setChipsData(newChipsData);
  fetchData.propertyType =
    newPropertyType === "SFR" ? "Single Family/Detached" : newPropertyType;
}

export function checkMinDataFilled(
  loanContextData: ObjectType,
  noAssignedPartnerStr: string,
  loanType?: string,
  fetchData?: any,
) {
  const {
    loanDetailsConfig,
    propertyDetails,
    loanDetails,
    propertyDetailsConfig,
    defaultSelectedDataView,
    setNoAssignedPartner,
  } = loanContextData;
  let isError = false;
  loanDetailsConfig?.fields?.map((subField: ObjectType) => {
    subField?.fields?.map((field: ObjectType) => {
      if (
        field.required &&
        (defaultSelectedDataView === "minimumDataView"
          ? field?.minimumDataView
          : field?.isVisible) &&
        !field?.isAutoCalculated(loanType, defaultSelectedDataView)
      ) {
        const error = field.getFieldErrors(
          loanDetails?.[subField?.label]?.[field.label]
        );
        if (error) {
          isError = true;
        }
      }
    });
  });

  propertyDetailsConfig?.map((propConfig: ObjectType, parentIdx: number) => {
    propConfig?.fields?.map((propSubField: ObjectType) => {
      if (!Array.isArray(propSubField)) {
        propSubField?.fields?.map((field: ObjectType) => {
          if (
            field.required &&
            (defaultSelectedDataView === "minimumDataView"
              ? field?.minimumDataView
              : field?.isVisible) &&
            !field?.isAutoCalculated(loanType, defaultSelectedDataView)
          ) {
            const error = field.getFieldErrors(
              propertyDetails[parentIdx]?.[propSubField.label]?.[field.label]
            );

            if (error) {
              isError = true;
            }
          }
        });
      } else {
        propSubField?.map(
          (unitParentField: ObjectType, unitParentIdx: number) => {
            unitParentField?.fields?.map((unitField: ObjectType) => {
              if (
                unitField.required &&
                (defaultSelectedDataView === "minimumDataView"
                  ? unitField?.minimumDataView
                  : unitField?.isVisible) &&
                !unitField?.isAutoCalculated(loanType, defaultSelectedDataView)
              ) {
                const error = unitField.getFieldErrors(
                  propertyDetails[parentIdx]?.[unitParentField.label][
                  unitParentIdx
                  ]?.[unitField.label]
                );

                if (error) {
                  isError = true;
                }
              }
            });
          }
        );
      }
    });
  });

  if ((!isError) || fetchData?.tcLoanId) {
    setNoAssignedPartner(noAssignedPartnerStr);
  } else {
    setNoAssignedPartner("eligibilityAndPricingText");
  }
}

export const fetchGuidelineEvalResult = async (
  loanId: string | any,
  loanType: string | undefined,
  propType: string | undefined,
  loanContextData: ObjectType,
  RootContextData: ObjectType,
  templateResponseData: any,
  setTemplateResponseData: any,
  dynamicConfig: any,
  templateLabels: any,
  tempLPData?: any
) => {
  const {
    loanDetails,
    propertyDetails,
    setAssignedPartner,
    setLoanDetails,
    autoCalculatedPropResults,
    setRatesAsOfDate,
    defaultSelectedDataView,
    assignedPartner,
    setAutoCalculatedLoanResults,
    setAutoCalculatedPropResults,
    setRulesResponse,
    fetchData,
    chipsData,
    setEligiblePartnerResults,
    setPartnersResults,
    setCriticalTests
  } = loanContextData;
  try {
    const tempLoanData = Object.keys(loanDetails).length
      ? { ...loanDetails }
      : { ...tempLPData };
    const tempPropertiesResult = [...propertyDetails];

    let loanTypeParam: string = "";
    if (loanType === "InvestorDSCR") {
      loanTypeParam = "30year";
    } else if (loanType === "BridgeLoan") {
      loanTypeParam = "bridge";
    }

    const loanTypeStr = loanTypeParam === "30year" ? "30Year" : "BRIDGE";
    const orgId = getCookie("orgId") || dynamicConfig?.clientId;
    const orgBucketCategory = getCookie("orgBucketCategory");
    const orgFundingType = getCookie("orgFundingType");

    const reqBody = normalizeReqBody(
      tempLoanData,
      propertyDetails,
      loanTypeStr,
      loanId,
      defaultSelectedDataView,
      chipsData,
      propType,
      { orgId, orgBucketCategory, orgFundingType },
      fetchData
    );

    const publicAPI2Headers = {
      accept: "application/json",
      "kc-realm": dynamicConfig?.ViteAppKcRealm,
    };

    const { data } = await getGuidlineEvaluationResult(loanType, reqBody);

    let result: ObjectType = data;
    let tempResultKeys: ObjectType = {};
    let tempLoanResult: ObjectType = {};
    let tempPropertyResult: ObjectType = { ...autoCalculatedPropResults };

    let tempCriticalTests: ObjectType = {};

    for (const tpId of Object.keys(result)) {
      const currentPartner: any = result?.[tpId];
      const ruleVersion: string =
        currentPartner?.loanResult?.[loanId]
          ?.ruleVersion?.RULE_VERSION || "";

      const criticalResponse: any = await getCriticalTests(ruleVersion);
      tempCriticalTests[tpId] = [
        ...(criticalResponse?.data?.criticalTestLoanEligibility ?? [])
      ];
    }
    tempCriticalTests = JSON.parse(JSON.stringify({ ...tempCriticalTests }));
    const tempLoanId: any = loanId;
    if (loanTypeParam === "30year") {
      const eligiblePayload = {
        tpResultMap: {
          [tempLoanId]: {
            FES: data,
          },
        },
        loanId,
        loanStage: "FES",
        loan: {
          loanEconomics: {
            originalLoanAmount: (
              tempLoanData?.loanEconomics?.originalLoanAmount ||
              tempLoanData?.loanEconomics?.initialLoanAmount ||
              tempLoanData?.loanEconomics?.borrowerRequested
            )?.toString(),
          },
        },
      };

      const eligibleResult = await getEligibleResult(
        eligiblePayload,
        publicAPI2Headers
      );

      const partnerId = loanDetails?.takeoutPartnerStatus === "OVERRIDDEN" ? loanDetails?.takeOutPartner : eligibleResult?.data?.assignedPartner || "";

      if (partnerId) {
        result = data[partnerId];
        tempResultKeys = Object.keys(result?.loanResult);
        tempLoanResult = result?.loanResult[tempResultKeys[0]];
        tempPropertyResult = result?.propertiesResults;
        setAssignedPartner(partnerId ?? "");
        setLoanDetails({ ...loanDetails, takeOutPartner: partnerId });
        getTestTabResultData(
          data,
          tempLoanData,
          publicAPI2Headers,
          true,
          tempLoanResult,
          loanTypeStr,
          fetchData,
          loanContextData,
          RootContextData,
          templateResponseData,
          setTemplateResponseData,
          dynamicConfig,
          templateLabels,
          partnerId ?? "",
          tempCriticalTests
        );
        checkMinDataFilled(loanContextData, "", loanType);
      } else {
        checkMinDataFilled(loanContextData, "noAssignedPartner", loanType);
        setAssignedPartner("");
        getTestTabResultData(
          data,
          tempLoanData,
          publicAPI2Headers,
          true,
          tempLoanResult,
          loanTypeStr,
          fetchData,
          loanContextData,
          RootContextData,
          templateResponseData,
          setTemplateResponseData,
          dynamicConfig,
          templateLabels,
          eligibleResult?.data?.assignedPartner ?? "",
          tempCriticalTests
        );
        const currentKey = Object.keys(data)[0];
        result = data[currentKey];
        tempResultKeys = Object.keys(result?.loanResult);
        tempLoanResult = result?.loanResult[tempResultKeys[0]];
        tempPropertyResult = result?.propertiesResults;
      }
      setEligiblePartnerResults(eligibleResult?.data);
    } else {
      tempResultKeys = Object.keys(data?.loanResult);
      tempLoanResult = data?.loanResult[tempResultKeys[0]];
      tempPropertyResult = result?.propertiesResults;
    }
    if (loanTypeStr === "BRIDGE") {
      getTestTabResultData(
        data,
        tempLoanData,
        publicAPI2Headers,
        false,
        tempLoanResult,
        loanTypeStr,
        fetchData,
        loanContextData,
        RootContextData,
        templateResponseData,
        setTemplateResponseData,
        dynamicConfig,
        templateLabels,
      );
    }

    // Used to populate the autocalculated fields
    const finalLoanData: any = normalizeResultLoanData(
      tempLoanData,
      tempLoanResult,
      loanTypeStr,
      tempPropertyResult,
      tempPropertiesResult,
      fetchData
    );

    let asOfDate = tempLoanResult?.ruleVersion?.RATE_SHEET?.substr(1, 8) ?? "";

    if (asOfDate) {
      asOfDate = moment(asOfDate, "DD-MM-YY").format("DD-MM-YYYY");
    }

    const { tempLoanDetails, propertyResult } = finalLoanData;
    setAutoCalculatedLoanResults({ ...tempLoanDetails });
    setAutoCalculatedPropResults([...propertyResult]);
    setRulesResponse(data);
    setRatesAsOfDate(asOfDate);
    setPartnersResults(data);
    setCriticalTests(tempCriticalTests);
    return { rulesData: data, asOfDate, tempCriticalTests };
  } catch (err: any) {
    console.error(err);
  }
};

export function handleInputChange(
  value: any,
  field: any,
  subComponentLabel: string,
  translation: any,
  loanContextData: ObjectType,
  component?: ObjectType | undefined,
  childIdx?: number | undefined,
  unitIdx?: number,
  isCurrency?: boolean,
  isPercentage?: boolean
) {
  const {
    loanFormErrors,
    propFormErrors,
    loanDetails,
    propertyDetails,
    setLoanFormErrors,
    setPropFormErrors,
    setLoanDetails,
    setPropertyDetails,
    setLoanDetailsConfig,
    setPropertyDetailsConfig,
    setBorrowerInformationConfig,
    setDebouncedValue,
    chipsData,
    setChipsData,
    fetchData,
  } = loanContextData;

  const tempValue = value;
  let tempFormErrors: ObjectType = { ...loanFormErrors };
  const tempPropFormErrors: ObjectType[] = [...propFormErrors];

  let tempLoanDetails = JSON.parse(JSON.stringify(loanDetails));
  let tempPropertyDetails = JSON.parse(JSON.stringify(propertyDetails));
  const checkComponent = tempFormErrors?.[subComponentLabel]?.[
    "creditScoreActual"
  ]
    ? "creditScoreActual"
    : "originalCreditScore";
  if (
    subComponentLabel === "borrowerInformation" &&
    field.label === "immigrationStatus"
  ) {
    if (
      tempValue === "Foriegn National" &&
      tempFormErrors?.[subComponentLabel]?.[checkComponent]
    ) {
      delete tempFormErrors[subComponentLabel][checkComponent];
    }
  }

  if (
    subComponentLabel === "borrowerInformation" &&
    field.label === "immigrationStatus"
  ) {
    if (
      tempValue === "Foriegn National" &&
      tempFormErrors[subComponentLabel]["creditScoreActual"]
    ) {
      delete tempFormErrors[subComponentLabel]["creditScoreActual"];
    }
  }
  if (field.required) {
    const error = field?.getFieldErrors(value);

    if (["loanDetails", "borrowerDetails"].includes(component?.label)) {
      if (!error && tempFormErrors?.[subComponentLabel]?.[field?.label]) {
        delete tempFormErrors[subComponentLabel][field.label];
      } else {
        tempFormErrors = {
          ...tempFormErrors,
          [subComponentLabel]: {
            ...tempFormErrors[subComponentLabel],
            [field.label]: translation(error),
          },
        };
      }
    } else {
      tempPropertyDetails.map((propDetail: ObjectType, idx: number) => {
        if (!error) {
          if (
            tempPropFormErrors &&
            tempPropFormErrors[idx]?.[subComponentLabel]?.[field?.label]
          ) {
            delete tempPropFormErrors[idx]?.[subComponentLabel]?.[field.label];
          } else if (
            isFalsyButNotZero(unitIdx) &&
            tempPropFormErrors[idx]?.[subComponentLabel]?.[unitIdx as number]?.[
            field.label
            ]
          ) {
            delete tempPropFormErrors[idx]?.[subComponentLabel]?.[
              unitIdx as number
            ][field.label];
          }
        } else {
          if (idx === childIdx) {
            let tempPropFormError: any = tempPropFormErrors[idx] || {};
            if (idx === childIdx) {
              if (!isFalsyButNotZero(unitIdx)) {
                tempPropFormError = {
                  ...tempPropFormError,
                  [subComponentLabel]: {
                    ...tempPropFormError[subComponentLabel],
                    [field.label]: translation(error),
                  },
                };
              } else {
                const unitInformations: ObjectType[] = [];
                propDetail?.[subComponentLabel]?.map(
                  (unitInfo: ObjectType, unitInfoIdx: number) => {
                    if (unitInfoIdx === unitIdx) {
                      unitInformations[unitInfoIdx] = {
                        [field.label]: translation(error),
                      };
                    }
                  }
                );
                tempPropFormError = {
                  ...tempPropFormError,
                  [subComponentLabel]: [...unitInformations],
                };
              }
            }
            tempPropFormErrors[idx] = tempPropFormError;
          }
        }
      });
    }
  }

  if (["loanDetails", "borrowerDetails"].includes(component?.label)) {
    tempLoanDetails = {
      ...tempLoanDetails,
      [subComponentLabel]: {
        ...tempLoanDetails[subComponentLabel],
        [field.label]: tempValue,
      },
    };

    if (
      ["interestOnlyFlag", "borrowerGuarantor"].includes(field?.label) &&
      tempValue === "No"
    ) {
      const label =
        field.label === "interestOnlyFlag" ? "ioPeriod" : "immigrationStatus";
      tempLoanDetails = {
        ...tempLoanDetails,
        [subComponentLabel]: {
          ...tempLoanDetails[subComponentLabel],
          [label]: "",
        },
      };
    }
    if (field.label === "loanPurpose" && tempValue === "Purchase") {
      tempLoanDetails = {
        ...tempLoanDetails,
        [subComponentLabel]: {
          ...tempLoanDetails[subComponentLabel],
          cashOutFlag: "No Cash Out",
        },
      };
    }
    if (field.label === "numberOfProperties") {
      tempLoanDetails = {
        ...tempLoanDetails,
        [subComponentLabel]: {
          ...tempLoanDetails[subComponentLabel],
          crossCollaterlization: tempValue <= 1 ? "N" : "Y",
          predominantPropertyType: null,
          predominantState: ""
        },
      };
    }
    if (field.label === "exitStrategy" && tempValue !== "Rehab to Hold/Rent") {
      tempPropertyDetails = tempPropertyDetails.map(
        (propDetail: ObjectType, idx: number) => {
          const tempPropDetail = { ...propDetail };
          if (!isFalsyButNotZero(unitIdx)) {
            return {
              ...tempPropDetail,
              ["propertyEconomics"]: {
                ...tempPropDetail["propertyEconomics"],
                ["estimatedRentPerYearUponCompletion"]: "",
              },
            };
          }
          return tempPropDetail;
        }
      );
    }
    if (field.label === "exitStrategy" && tempValue !== "Build to Rent" && chipsData.includes("Ground Up")) {
      tempPropertyDetails = tempPropertyDetails.map(
        (propDetail: ObjectType, idx: number) => {
          const tempPropDetail = { ...propDetail };
          if (!isFalsyButNotZero(unitIdx)) {
            return {
              ...tempPropDetail,
              ["propertyEconomics"]: {
                ...tempPropDetail["propertyEconomics"],
                ["grossPotentialRentMonthly"]: "",
              },
            };
          }
          return tempPropDetail;
        }
      );
    }
    if (
      ["crossCollaterlization"].includes(field?.label) &&
      tempValue === "N"
    ) {
      tempLoanDetails = {
        ...tempLoanDetails,
        [subComponentLabel]: {
          ...tempLoanDetails[subComponentLabel],
          ["predominantPropertyType"]: "",
          ["predominantState"]: "",
        },
      };
    }
  } else {
    tempPropertyDetails = tempPropertyDetails.map(
      (propDetail: ObjectType, idx: number) => {
        if (idx === childIdx) {
          let indexOfMostRestrictiveProperty = 0
          if(fetchData?.autoCalculatedLoanResults && fetchData?.propertyDetails?.length) {
            indexOfMostRestrictiveProperty = fetchData?.autoCalculatedLoanResults?.loanCharacterisation?.indexOfMostRestrictiveProperty ?? 0
          }
          // if (childIdx === 0) {
            if (childIdx === indexOfMostRestrictiveProperty) {
            if (field.label === "propertyType") {
              updateChipsData({...fetchData}, chipsData, setChipsData, tempValue);
              delete tempPropFormErrors[idx]?.[subComponentLabel]?.[
                "numberOfUnits"
              ];
              return {
                ...propDetail,
                ["propertyInformation"]: {
                  ...propDetail["propertyInformation"],
                  ["propertyType"]: tempValue,
                  ["numberOfUnits"]: "",
                },
              };
            }
          }
          if (!isFalsyButNotZero(unitIdx)) {
            return {
              ...propDetail,
              [subComponentLabel]: {
                ...propDetail[subComponentLabel],
                [field.label]: tempValue,
              },
            };
          } else {
            if (propDetail?.[subComponentLabel]) {
              const unitInformations = propDetail?.[subComponentLabel]?.map(
                (unitInfo: ObjectType, unitInfoIdx: number) => {
                  if (unitInfoIdx === unitIdx) {
                    return {
                      ...unitInfo,
                      [field.label]: tempValue,
                    };
                  }
                  return unitInfo;
                }
              );
              return {
                ...propDetail,
                [subComponentLabel]: [...unitInformations],
              };
            }
            return {
              ...propDetail,
              [subComponentLabel]: [{ [field.label]: tempValue }],
            };
          }
        }
        return propDetail;
      }
    );
  }

  const dynamicFields = getDynamicFields(
    value,
    field,
    subComponentLabel,
    loanContextData,
    component,
    childIdx,
    unitIdx,
    chipsData
  );

  setLoanDetailsConfig(dynamicFields?.tempLoanDetailsConfig);
  setPropertyDetailsConfig(dynamicFields?.tempPropertyDetailsConfig);
  setBorrowerInformationConfig(dynamicFields?.tempBorrowerInformationConfig);
  setPropertyDetails(tempPropertyDetails);
  setLoanDetails(tempLoanDetails);
  setLoanFormErrors(tempFormErrors);
  setPropFormErrors(tempPropFormErrors);
  postGuidelineEvaluation(setDebouncedValue, value);
}

export function handleIconButtonClick(
  label: string,
  index: number,
  loanContextData: ObjectType,
  unitIdx?: number,
  propertyIdx?: number
) {
  const {
    defaultSelectedDataView,
    propertyDetails,
    propertyDetailsConfig,
    propertyDetailTemplate,
    propertyDetailsConfigTemplate,
    unitInfoConfigTemplate,
    unitInfoTemplate,
    autoCalculatedFields,
    setPropertyDetails,
    setPropertyDetailsConfig,
    setDebouncedValue,
    setAutoCalculatedFields,
    chipsData,
    setChipsData,
    fetchData,
    loanDetails
  } = loanContextData;

  let tempPropertyDetailsConfig = [...propertyDetailsConfig];
  let tempPropertyDetails = JSON.parse(JSON.stringify(propertyDetails));
  const tempAutoCalculatedFields = { ...autoCalculatedFields };

  let newPropertyType: string;

  switch (label) {
    case "deleteProperty":
      tempPropertyDetails = tempPropertyDetails.filter(
        (propDetails: ObjectType, idx: number) => idx !== index
      );
      tempPropertyDetailsConfig = tempPropertyDetailsConfig.filter(
        (propDetails: ObjectType, idx: number) => idx !== index
      );
      newPropertyType =
        tempPropertyDetails[0]?.["propertyInformation"]["propertyType"];
      updateChipsData(fetchData, chipsData, setChipsData, newPropertyType);
      break;
    case "addProperty":
      tempPropertyDetails.push(propertyDetailTemplate);
      tempPropertyDetailsConfig.push({
        ...propertyDetailsConfigTemplate,
        fields: propertyDetailsConfigTemplate?.fields?.map((field: any) => {
          if (["propertyInformation", "propertyEconomics"].includes(field?.id)) {
            const isBuildToRent = loanDetails?.loanInformation?.exitStrategy === "Build to Rent";
            const isRehabToHoldRent = loanDetails?.loanInformation?.exitStrategy === "Rehab to Hold/Rent";
            const isNotFullDataView = defaultSelectedDataView !== "fullDataView";

            return {
              ...field,
              fields: field.fields.map((subField: any) => {
                switch (subField.id) {
                  case "monthlyPropertyGrossRent":
                  case "rentalCharacterization":
                    return {
                      ...subField,
                      minimumDataView: isNotFullDataView,
                      isVisible: isNotFullDataView,
                    };
                  case "grossPotentialRentMonthly":
                    return {
                      ...subField,
                      minimumDataView: isBuildToRent,
                      isVisible: isBuildToRent,
                    };
                  case "estimatedRentPerYearUponCompletion":
                    return {
                      ...subField,
                      minimumDataView: isRehabToHoldRent,
                      isVisible: isRehabToHoldRent,
                    };
                  default:
                    return subField;
                }
              }),
            };
          }

          return field;
        }),
      });
      if (tempAutoCalculatedFields) {
        tempAutoCalculatedFields?.fields?.forEach((field: any) => {
          if (Array.isArray(field)) {
            field.push(field[0]);
          }
        });
      }
      break;
    case "addMore":
      tempPropertyDetailsConfig = tempPropertyDetailsConfig?.map(
        (propConfig: ObjectType, idx: number) => {
          if (idx === propertyIdx) {
            const tempConfigField = propConfig?.fields?.map(
              (field: ObjectType) => {
                if (Array.isArray(field)) {
                  return [...field, unitInfoConfigTemplate];
                }
                return field;
              }
            );
            return { ...propConfig, fields: tempConfigField };
          }
          return propConfig;
        }
      );

      tempPropertyDetails = tempPropertyDetails?.map(
        (propConfig: ObjectType, idx: number) => {
          if (idx === propertyIdx) {
            return {
              ...propConfig,
              unitInformation: [
                ...(propConfig?.unitInformation ?? {}),
                unitInfoTemplate[0],
              ],
            };
          }
          return propConfig;
        }
      );

      break;
    case "delete":
      tempPropertyDetailsConfig = tempPropertyDetailsConfig.map(
        (propConfig: ObjectType, idx: number) => {
          if (idx === propertyIdx) {
            const tempConfigField = propConfig?.fields?.map(
              (field: ObjectType) => {
                if (Array.isArray(field)) {
                  return field.filter(
                    (unitField: ObjectType, unitFieldIdx: number) =>
                      unitFieldIdx !== unitIdx
                  );
                }
                return field;
              }
            );
            return { ...propConfig, fields: tempConfigField };
          }
          return propConfig;
        }
      );

      tempPropertyDetails = tempPropertyDetails.map(
        (propConfig: ObjectType, idx: number) => {
          if (idx === propertyIdx) {
            return {
              ...propConfig,
              unitInformation: propConfig?.unitInformation.filter(
                (unitInfo: ObjectType, unitInfoIdx: number) =>
                  unitIdx !== unitInfoIdx
              ),
            };
          }
          return propConfig;
        }
      );
      break;
    default:
      break;
  }

  postGuidelineEvaluation(setDebouncedValue, tempPropertyDetails);
  setPropertyDetails(tempPropertyDetails);
  setPropertyDetailsConfig(tempPropertyDetailsConfig);
  setAutoCalculatedFields(tempAutoCalculatedFields);
}

export function renderSubComponentFields(
  subComponent: any,
  propIdx: any,
  data: any,
  component: ObjectType,
  loanType: string,
  theme: any,
  isApplicationSubmitted: boolean,
  translation: any,
  loanContextData: ObjectType,
  childIdx?: number | undefined,
  unitIdx?: number,
  isAutoCalculatedObj?: ObjectType
) {
  const {
    defaultSelectedDataView,
    expandLeverageAndTests,
    autoCalculatedLoanResults,
    autoCalculatedPropResults,
  } = loanContextData;
  const subComponentFields: any = subComponent?.fields?.filter((ele: any) => {
    if (defaultSelectedDataView === "fullDataView" && ele?.isVisible) {
      return ele;
    }
    return ele[defaultSelectedDataView] && ele?.isVisible;
  });

  const subLeftFields = subComponent?.fields?.filter(
    (ele: any) => ele.isExpandedView
  );

  const fieldsToRender = subComponentFields?.filter((field: any) => {
    let value = data?.[field.label];
    if (
      field?.isAutoCalculated &&
      field?.isAutoCalculated(loanType, defaultSelectedDataView)
    ) {
      if (isAutoCalculatedObj?.isAutoCalculated) {
        if (!isAutoCalculatedObj?.propLevel) {
          value = autoCalculatedLoanResults?.[subComponent?.label]?.[field?.id];
        } else {
          value =
            autoCalculatedPropResults[propIdx as number]?.[
            subComponent?.label
            ]?.[field?.label];
        }
      } else if (
        autoCalculatedLoanResults?.[subComponent?.label]?.[field?.id]
      ) {
        value = autoCalculatedLoanResults?.[subComponent?.label]?.[field?.id];
      } else {
        if (!isFalsyButNotZero(unitIdx)) {
          value =
            autoCalculatedPropResults[childIdx as number]?.[
            subComponent.label
            ]?.[field.label];
        } else {
          value =
            autoCalculatedPropResults.length &&
              autoCalculatedPropResults[childIdx as number]?.[subComponent.label]
                ?.length
              ? autoCalculatedPropResults[childIdx as number]?.[
              subComponent.label
              ][unitIdx as number]?.[field.label]
              : "";
        }
      }
    }
    const tempExceptions = [
      "loanFeatures",
      "loanCharacterisation",
      "marketData",
      "subMarketData",
    ];
    if (
      field?.isAutoCalculated &&
      field?.isAutoCalculated(loanType, defaultSelectedDataView) &&
      !isFalsyButNotZero(value)
    ) {
      if (!tempExceptions.includes(subComponent.label)) {
        return false;
      }
      return true;
    }
    return true;
  });

  return !expandLeverageAndTests ? (
    <div>
      <Grid container>
        {fieldsToRender?.map((field: any, idx: number) => {
          return (
            <Grid
              key={idx}
              item
              xs={field.columnSplit}
              className={idx < 2 ? "" : "mt-32"}
            >
              {renderFieldUI(
                field,
                idx,
                data?.[field.label],
                handleInputChange,
                loanType,
                theme,
                isApplicationSubmitted,
                translation,
                loanContextData,
                subComponent.label,
                component,
                childIdx,
                unitIdx,
                { ...isAutoCalculatedObj, propIdx }
              )}
            </Grid>
          );
        })}
      </Grid>
    </div>
  ) : (
    <div>
      <Grid container>
        {subLeftFields?.map((field: any, idx: number) => {
          return (
            <Grid key={idx} item sx={{ margin: "0 !important" }}>
              {renderFieldUI(
                field,
                idx,
                data?.[field.label],
                handleInputChange,
                loanType,
                theme,
                isApplicationSubmitted,
                translation,
                loanContextData,
                subComponent.label,
                component,
                childIdx,
                unitIdx
              )}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export function renderFieldUI(
  field: any,
  idx: any,
  value: any,
  handleOnChange: any,
  loanType: string | undefined,
  theme: any,
  isApplicationSubmitted: boolean,
  translation: any,
  loanContextData: ObjectType,
  subComponentLabel?: any,
  component?: ObjectType,
  childIdx?: number,
  unitIdx?: number,
  autoCalculatedData?: ObjectType
) {
  const {
    defaultSelectedDataView,
    autoCalculatedLoanResults,
    autoCalculatedPropResults,
    loanFormErrors,
    propFormErrors,
    addressOptions,
    zipCodeOptions,
  } = loanContextData;

  if (
    field?.isAutoCalculated &&
    field?.isAutoCalculated(loanType, defaultSelectedDataView)
  ) {
    if (autoCalculatedData?.isAutoCalculated) {
      if (!autoCalculatedData?.propLevel) {
        value = autoCalculatedLoanResults?.[subComponentLabel]?.[field?.id];
      } else {
        value =
          autoCalculatedPropResults[autoCalculatedData?.propIdx as number]?.[
          subComponentLabel
          ]?.[field?.label];
      }
    } else if (autoCalculatedLoanResults?.[subComponentLabel]?.[field?.id]) {
      value = autoCalculatedLoanResults?.[subComponentLabel]?.[field?.id];
    } else {
      if (!isFalsyButNotZero(unitIdx)) {
        value =
          autoCalculatedPropResults[childIdx as number]?.[subComponentLabel]?.[
          field.label
          ];
      } else {
        value =
          autoCalculatedPropResults.length &&
            autoCalculatedPropResults[childIdx as number]?.[subComponentLabel]
              ?.length
            ? autoCalculatedPropResults[childIdx as number]?.[
            subComponentLabel
            ][unitIdx as number]?.[field.label]
            : "";
      }
    }
  }

  let errorMsg = "";
  if (["loanDetails", "borrowerDetails"].includes(component?.label)) {
    errorMsg = loanFormErrors?.[subComponentLabel]?.[field.label] ?? "";
  } else if (isFalsyButNotZero(childIdx)) {
    if (!isFalsyButNotZero(unitIdx)) {
      errorMsg =
        propFormErrors?.[childIdx as number]?.[subComponentLabel]?.[
        field.label
        ];
    } else {
      errorMsg =
        propFormErrors?.[childIdx as number]?.[subComponentLabel]?.[
        unitIdx as number
        ]?.[field.label];
    }
  }

  switch (field?.type(loanType, defaultSelectedDataView)) {
    case "buttonGroup":
      return (
        <>
          {getTitleInfo(field, false, loanType, translation, loanContextData)}
          <div className="mt-4">
            <CustomButtonGroup
              buttons={field.data}
              selectedButton={value}
              field={field}
              handleDataViewChange={handleOnChange}
              subComponentLabel={subComponentLabel}
              className="button-grp-style"
              buttonSize="large"
              translation={translation}
              loanContextData={loanContextData}
              theme={theme}
              errorMsg={errorMsg ?? ""}
              isDataView={false}
              component={component}
              childIdx={childIdx}
              unitIdx={unitIdx}
              isApplicationSubmitted={isApplicationSubmitted}
            />
          </div>
        </>
      );
    case "dropDown":
      return (
        <>
          {getTitleInfo(field, false, loanType, translation, loanContextData)}
          <div className="mt-4">
            <CustomSelect
              data={field?.data ?? []}
              field={field}
              value={value ?? ""}
              onChange={handleOnChange}
              subComponentLabel={subComponentLabel}
              theme={theme}
              component={component}
              translation={translation}
              loanContextData={loanContextData}
              childIdx={childIdx}
              unitIdx={unitIdx}
              errorMsg={errorMsg ?? ""}
              defaultSelectedDataView={defaultSelectedDataView}
              loanType={loanType}
              checkEligibility={field.checkEligibility}
              isApplicationSubmitted={isApplicationSubmitted}
            />
          </div>
        </>
      );
    case "string":
    case "number":
      return (
        <>
          {getTitleInfo(field, false, loanType, translation, loanContextData)}
          <div className="mt-4">
            <CustomInput
              type={field?.type}
              field={field}
              value={value ?? ""}
              onChange={handleOnChange}
              subComponentLabel={subComponentLabel}
              theme={theme}
              component={component}
              translation={translation}
              loanContextData={loanContextData}
              childIdx={childIdx}
              unitIdx={unitIdx}
              errorMsg={errorMsg ?? ""}
              isCurrency={field.isCurrency}
              isPercentage={field.isPercentage}
              defaultSelectedDataView={defaultSelectedDataView}
              loanType={loanType}
              isApplicationSubmitted={isApplicationSubmitted}
            />
          </div>
        </>
      );
    case "date":
      return (
        <>
          {getTitleInfo(field, false, loanType, translation, loanContextData)}
          <div className="mt-4">
            <CustomDatePicker
              field={field}
              value={value}
              onChange={handleOnChange}
              subComponentLabel={subComponentLabel}
              theme={theme}
              component={component}
              translation={translation}
              loanContextData={loanContextData}
              childIdx={childIdx}
              unitIdx={unitIdx}
              errorMsg={errorMsg ?? ""}
              isApplicationSubmitted={isApplicationSubmitted}
            />
          </div>
        </>
      );
    case "autoComplete":
      return (
        <>
          {getTitleInfo(field, true, loanType, translation, loanContextData)}
          <div className="mt-4">
            <CustomAutoComplete
              label={translation(field?.label)}
              addressOptions={addressOptions}
              zipCodeOptions={zipCodeOptions}
              selectedAddressValue={value}
              selectedZipCodeValue={value}
              getAddressOptionLabel={getAddressOptionLabel}
              getPostalOptionLabel={getPostalOptionLabel}
              addressOnChange={addressOnChange}
              postalOnChange={postalOnChange}
              zipCodeInputValue={value}
              addressInputValue={value}
              postalCodeOnInputChange={postalCodeOnInputChange}
              addressOnInputChange={addressOnInputChange}
              errorMsg={errorMsg ?? ""}
              isAutoPopulated={field.isAutoPopulated}
              component={component}
              childIdx={childIdx}
              unitIdx={unitIdx}
              isApplicationSubmitted={isApplicationSubmitted}
              field={field}
              loanContextData={loanContextData}
              translation={translation}
            />
          </div>
        </>
      );
    case "radio":
      return (
        <>
          {getTitleInfo(field, true, loanType, translation, loanContextData)}
          <div className="mt-4">
            <CustomRadio
              field={field}
              options={field?.data}
              value={value}
              handleChange={handleCondoEligibilityModalChange}
              loanContextData={loanContextData}
              component={component}
              childIdx={childIdx}
              unitIdx={unitIdx}
              errorMsg={errorMsg ?? ""}
              isApplicationSubmitted={isApplicationSubmitted}
            />
          </div>
        </>
      );
    case "checkBox":
      return (
        <div className="mt-4">
          <CheckBox
            label={getTitleInfo(
              field,
              false,
              loanType,
              translation,
              loanContextData
            )}
            field={field}
            value={value}
            handleChange={handleOnChange}
            subComponentLabel={subComponentLabel}
            component={component}
            translation={translation}
            loanContextData={loanContextData}
            childIdx={childIdx}
            unitIdx={unitIdx}
            errorMsg={errorMsg ?? ""}
            isApplicationSubmitted={isApplicationSubmitted}
          />
        </div>
      );
    default:
      break;
  }
}

export function renderSubComponentUI(
  subComponent: any,
  idx: any,
  data: any,
  component: ObjectType,
  loanType: string,
  theme: any,
  translation: any,
  loanContextData: ObjectType,
  childIdx?: number,
  isAutoCalculatedObj?: ObjectType
) {
  const {
    defaultSelectedDataView,
    propertyDetails,
    isApplicationSubmitted,
    expandLeverageAndTests,
    setIsUnitExpanded,
    isUnitExpanded,
    autoCalculatedLoanResults,
    autoCalculatedPropResults,
  } = loanContextData;
 
  const showUnitInfo = subComponent[0]?.fields?.filter(
    (tempComponent: ObjectType) => {
      if (defaultSelectedDataView === "fullDataView") {
        return tempComponent?.isVisible;
      }
      return tempComponent?.minimumDataView && component?.isVisible;
    }
  )?.length;

  if (Array.isArray(subComponent) && showUnitInfo) {
    return (
      <>
        <h4>{translation(subComponent[0]?.label)}</h4>
        {subComponent.map((ele: ObjectType, i: number) => {
          const propLabel: string = `Property #${(childIdx as number) + 1}`;
          const unitLabel: string = `Unit ${(i as number) + 1}`;
          return !expandLeverageAndTests ? (
            <Accordion
              key={i}
              defaultExpanded={true}
              expanded={isUnitExpanded[propLabel]?.[unitLabel]}
              onChange={() =>
                setIsUnitExpanded({
                  ...isUnitExpanded,
                  [propLabel]: {
                    ...isUnitExpanded[propLabel],
                    [unitLabel]: !isUnitExpanded[propLabel]?.[unitLabel],
                  },
                })
              }
            >
              <UnitAccordionSummary>
                <div>
                  <h4>{`${translation("unit")} ${i + 1}`}</h4>
                </div>
                <div>
                  {ele?.buttons ? (
                    <div>
                      {ele?.buttons.map((button: any, idx: number) => {
                        return (
                          <IconButton
                            key={idx}
                            disabled={checkIconButtonDisabled(
                              subComponent,
                              button.label,
                              isApplicationSubmitted,
                              propertyDetails.length
                            )}
                            onClick={() =>
                              handleIconButtonClick(
                                button.label,
                                idx,
                                loanContextData,
                                i,
                                childIdx
                              )
                            }
                            sx={{
                              borderRadius: "0% !important;",
                            }}
                          >
                            <span style={{ fontSize: "12px" }}>
                              {translation(button.label)}
                            </span>
                            &ensp;
                            <img src={button.icon} alt="button-icon" />
                            &ensp;
                            {idx === 0 ? "| " : null}
                          </IconButton>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </UnitAccordionSummary>
              <AccordionDetails className="mt-12">
                <Grid container>
                  {renderSubComponentFields(
                    ele,
                    idx,
                    data ? data[i] : "",
                    component,
                    loanType,
                    theme,
                    isApplicationSubmitted,
                    translation,
                    loanContextData,
                    childIdx,
                    i
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : (
            ""
          );
        })}
      </>
    );
  } else {
    const subComponentFields: any = subComponent?.fields?.filter((ele: any) => {
      if (defaultSelectedDataView === "fullDataView" && ele.isVisible) {
        return ele;
      }
      return ele[defaultSelectedDataView] && ele.isVisible;
    });

    let label = translation(
      subComponentFields?.length ? subComponent?.label : ""
    );

    let tempFields: any = subComponent;
    if (isAutoCalculatedObj?.isAutoCalculated) {
      // if(!['marketData','subMarketData']?.includes(subComponent?.label))
      tempFields = checkIfAutoCalculated(
        subComponent,
        isAutoCalculatedObj?.propLevel,
        autoCalculatedLoanResults,
        autoCalculatedPropResults,
        idx
      );
      if (!tempFields?.fields?.length) {
        label = "";
      } else if (
        tempFields?.fields?.length &&
        isAutoCalculatedObj?.propLevel &&
        defaultSelectedDataView === "fullDataView" 
        // !['marketData','subMarketData']?.includes(subComponent?.label)
      ) {
        label = `${translation(subComponent?.label)} - Property #${idx + 1}`;
      }
    }

    return (
      <>
        {!expandLeverageAndTests ? <h4>{label}</h4> : ""}
        {renderSubComponentFields(
          tempFields,
          idx,
          data,
          component,
          loanType,
          theme,
          isApplicationSubmitted,
          translation,
          loanContextData,
          childIdx,
          undefined,
          isAutoCalculatedObj
        )}
      </>
    );
  }
}

export function checkIconButtonDisabled(
  component: any,
  label: any,
  isApplicationSubmitted: boolean,
  propertyDetailsLength?: number
) {
  if (label === "addProperty" || label === "addMore") {
    return isApplicationSubmitted;
  }
  if (label === "deleteProperty") {
    return propertyDetailsLength === 1 || isApplicationSubmitted;
  }
  if (label === "delete") {
    return component.length === 1 || isApplicationSubmitted;
  }
  return false;
}

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "6px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const UnitAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<img src={AccordionDownArrow} style={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  background: "rgba(234, 222, 248, 0.50)",
  borderRadius: "6px",
  border: "1px solid #DBE2FF",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function checkIfAutoCalculated(
  subComponent: any,
  propLevel: boolean,
  autoCalculatedLoanResults: any,
  autoCalculatedPropResults: any,
  propIdx: number
) {
  const tempSubComponent = {
    ...subComponent,
    fields: subComponent.fields.filter((field: any) => {
      if (propLevel && Object.keys(field).length) {
        return autoCalculatedPropResults[propIdx as number]?.[
          subComponent.label
        ]?.[field?.id];
      } else {
        return autoCalculatedLoanResults?.[subComponent.label]?.[field?.id];
      }
    }),
  };

  return tempSubComponent;
}
