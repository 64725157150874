// enums
export enum LoanSizerEnum {
  oneFourFamily = "1-4 Family",
  multifamilyMixedUse = "Multifamily/MixedUse",
  groundUp = "Ground-Up",
}

export enum LeaseStatusEnum {
  occupiedLongTerm = "Occupied / Long Term Rental",
  unOcupiedLongTerm = "Unoccupied / Long Term Rental",
  occupiedShortTerm = "Occupied / Short Term Rental",
  unOccupiedShortTerm = "Unoccupied / Short Term Rental",
}

export enum RecourseEnum {
  fullRecourse = "Full Recourse",
  limitedRecourse = "Limited Recourse",
  nonRecourse = "Non-Recourse",
}

// dropDown Values
export const borrowerTypeDropDownValues = [
  { label: "entity", value: "Entity" },
  { label: "individual", value: "Individual" },
];

export const generalValuationSource = [
  { label: "appraisal", value: "Appraisal" },
  { label: "appraisalPending", value: "Appraisal Pending" },
];

export const bridgeGroundUpValutionSource = [
  { label: "appraisal", value: "Appraisal" },
  { label: "borrower", value: "Borrower" },
];

export const immigrationStatusDSCRDropDownValues = [
  { label: "foreignNational", value: "Foriegn National" },
  {
    label: "permenantResidentAlien",
    value: "Permanent Resident Alien",
  },
  {
    label: "nonPermanentResidnetAlien",
    value: "Non-Permanent Resident Alien",
  },
];

export const immigrationStatusDropDownValues = [
  { label: "greenCard", value: "Green Card" },
  { label: "ead", value: "Employment Authorization Document (EAD)" },
  { label: "h1bVisa", value: "H-1B Visa" },
  { label: "l1Visa", value: "L-1 Visa" },
  { label: "e2Visa", value: "E-2 Visa" },
  { label: "marriedToUSCitizen", value: "Married to a US Citizen" },
  { label: "sponsoredByUsCompanies", value: "Sponsored by US-based companies" },
];

export const loanPurposeDropDownValues = [
  { label: "purchase", value: "Purchase" },
  { label: "cashOutRefinance", value: "Cash Out Refinance" },
  { label: "rateTermRefinance", value: "Rate/Term Refinance" },
  { label: "delayedPurchase", value: "Delayed Purchase" },
];

export const bridgeLoanPuposeDropDownValues = [
  { label: "purchase", value: "Purchase" },
  { label: "refinance", value: "Refinance" },
];

export const condoEligibilityDropDownValues = [
  { label: "standard", value: "Standard" },
  { label: "min", value: "Min" },
  { label: "notEligible", value: "Not Eligible" },
  { label: "naText", value: "N/A" },
];

export const condoEligibilityV2DropDownValues = [
  { label: "warrantable", value: "Warrantable" },
  { label: "nonWarrantable", value: "Non-Warrantable" },
  { label: "notEligible", value: "Not Eligible" },
  { label: "naText", value: "N/A" },
];

export const prepaymentPenaltyMonths = [
  { label: "60", value: 60 },
  { label: "48", value: 48 },
  { label: "36", value: 36 },
  { label: "24", value: 24 },
  { label: "12", value: 12 },
  { label: "0", value: 0 },
];

export const prepaymentPenaltyTypeDropDownValues = [
  { label: "5/5/5/5/5", value: "5/5/5/5/5" },
  { label: "5/4/3/2/1", value: "5/4/3/2/1" },
  { label: "1/1/1/1/1", value: "1/1/1/1/1" },
  { label: "4/3/2/1", value: "4/3/2/1" },
  { label: "1/1/1/1", value: "1/1/1/1" },
  { label: "5/4/3", value: "5/4/3" },
  { label: "3/2/1", value: "3/2/1" },
  { label: "1/1/1", value: "1/1/1" },
  { label: "2/1", value: "2/1" },
  { label: "1/1", value: "1/1" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "0", value: "0" },
];

export const rateTypeDropDownValues = [
  { label: "fixed", value: "Fixed" },
  // { label: "adjustable", value: "Adjustable" },
];

export const predominantPropType = [
  { label: "sfr", value: "SFR" },
  { label: "24UnitText", value: "2-4 Unit" },
  { label: "condoText", value: "Condo" },
  { label: "townHomeText", value: "Townhome" },
];

export const propertyTypeDropDownValues = [
  { label: "unitMultifamily", value: "5+ Unit Multifamily" },
  { label: "mixedUse", value: "Mixed Use" },
];

export const thirdPartyValuationStatusDropDownValues = [
  { label: "ordered", value: "Ordered" },
  { label: "pending", value: "Pending" },
];

export const bridgeGroundUpExitStrategyDropDownValues = [
  { label: "buildToFlipSell", value: "Build to Flip/Sell" },
  { label: "buildToRent", value: "Build to Rent" },
];

export const bridgeGenExitStrategyDropDownValues = [
  { label: "rehabRentHold", value: "Rehab to Hold/Rent" },
  { label: "rehabToFlip", value: "Rehab to Flip" },
];

export const closingCostDropDownValues = [
  { label: "0.0000%", value: "0.0000%" },
  { label: "0.5000%", value: "0.5000%" },
  { label: "1.0000%", value: "1.0000%" },
  { label: "1.5000%", value: "1.5000%" },
  { label: "2.0000%", value: "2.0000%" },
  { label: "2.5000%", value: "2.5000%" },
  { label: "3.0000%", value: "3.0000%" },
  { label: "3.5000%", value: "3.5000%" },
  { label: "4.0000%", value: "4.0000%" },
  { label: "4.5000%", value: "4.5000%" },
  { label: "5.0000%", value: "5.0000%" },
  { label: "5.5000%", value: "5.5000%" },
  { label: "6.0000%", value: "6.0000%" },
  { label: "6.5000%", value: "6.5000%" },
  { label: "7.0000%", value: "7.0000%" },
  { label: "7.5000%", value: "7.5000%" },
  { label: "8.0000%", value: "8.0000%" },
  { label: "8.5000%", value: "8.5000%" },
  { label: "9.0000%", value: "9.0000%" },
  { label: "9.5000%", value: "9.5000%" },
  { label: "10.0000%", value: "10.0000%" },
];

export enum LoanStructureTypes {
  multipleDraw = "multipleDraws",
  singleDraw = "singleDraw",
  singleFunding = "singleFunding",
}

export const loanStructureDropDown = [
  { label: LoanStructureTypes.multipleDraw, value: "Multiple Draws" },
  {
    label: LoanStructureTypes.singleFunding,
    value: "Single Funding (With Rehab Escrow)",
  },
];

export const bridgeRehabLoanStructureDropDown = [
  ...loanStructureDropDown,
  {
    label: LoanStructureTypes.singleDraw,
    value: "Single Draw (No Rehab Escrow)",
  },
];
export const irFundingSources = [
  { label: "none", value: "None" },
  { label: "3monthsOutOfPocket", value: "3 Months Out of Pocket" },
  { label: "6monthsOutOfPocket", value: "6 Months Out of Pocket" },
  // { label: "9monthsOutOfPocket", value: "9 Months Out of Pocket" },
  { label: "3monthsFundedViaLoan", value: "3 Months Funded via Loan" },
  { label: "6monthsFundedViaLoan", value: "6 Months Funded via Loan" },
  { label: "9monthsFundedViaLoan", value: "9 Months Funded via Loan" },
  { label: "fundedViaLoan", value: "Funded via Loan" },
];

export const rentalCharacterizationDropDownValues = [
  { label: "occupiedLongTerm", value: LeaseStatusEnum.occupiedLongTerm },
  { label: "unOcupiedLongTerm", value: LeaseStatusEnum.unOcupiedLongTerm },
  { label: "occupiedShortTerm", value: LeaseStatusEnum.occupiedShortTerm },
  { label: "unOccupiedShortTerm", value: LeaseStatusEnum.unOccupiedShortTerm },
];

export const currentUsageDropDownValues = [
  {
    label: "shortTermRental",
    value: "Short Term Rental",
  },
  {
    label: "longTermRental",
    value: "Long Term Rental",
  },
];

export const recourseDropDownValue = [
  { label: "fullRecourse", value: RecourseEnum.fullRecourse },
  { label: "limitedRecourse", value: RecourseEnum.limitedRecourse },
  { label: "nonRecourse", value: RecourseEnum.nonRecourse },
];

export const dscrInterestRateDropDownValue: any = [{
  label: "5.500%",
  value: 5.5
}, {
  label: "5.625%",
  value: 5.625
}, {
  label: "5.750%",
  value: 5.75
}, {
  label: "5.875%",
  value: 5.875
}, {
  label: "6.000%",
  value: 6
}, {
  label: "6.125%",
  value: 6.125
}, {
  label: "6.250%",
  value: 6.25
}, {
  label: "6.375%",
  value: 6.375
}, {
  label: "6.500%",
  value: 6.5
}, {
  label: "6.625%",
  value: 6.625
}, {
  label: "6.750%",
  value: 6.75
}, {
  label: "6.875%",
  value: 6.875
}, {
  label: "7.000%",
  value: 7
}, {
  label: "7.125%",
  value: 7.125
}, {
  label: "7.250%",
  value: 7.25
}, {
  label: "7.375%",
  value: 7.375
}, {
  label: "7.500%",
  value: 7.5
}, {
  label: "7.625%",
  value: 7.625
}, {
  label: "7.750%",
  value: 7.75
}, {
  label: "7.875%",
  value: 7.875
}, {
  label: "8.000%",
  value: 8
}, {
  label: "8.125%",
  value: 8.125
}, {
  label: "8.250%",
  value: 8.25
}, {
  label: "8.375%",
  value: 8.375
}, {
  label: "8.500%",
  value: 8.5
}, {
  label: "8.625%",
  value: 8.625
}, {
  label: "8.750%",
  value: 8.75
}, {
  label: "8.875%",
  value: 8.875
}, {
  label: "9.000%",
  value: 9
}, {
  label: "9.125%",
  value: 9.125
}, {
  label: "9.250%",
  value: 9.25
}, {
  label: "9.375%",
  value: 9.375
}, {
  label: "9.500%",
  value: 9.5
}, {
  label: "9.625%",
  value: 9.625
}, {
  label: "9.750%",
  value: 9.75
}, {
  label: "9.875%",
  value: 9.875
}, {
  label: "10.000%",
  value: 10
}, {
  label: "10.125%",
  value: 10.125
}, {
  label: "10.250%",
  value: 10.25
}, {
  label: "10.375%",
  value: 10.375
}, {
  label: "10.500%",
  value: 10.5
}, {
  label: "10.625%",
  value: 10.625
}, {
  label: "10.750%",
  value: 10.75
}, {
  label: "10.875%",
  value: 10.875
}, {
  label: "11.000%",
  value: 11
}, {
  label: "11.125%",
  value: 11.125
}, {
  label: "11.250%",
  value: 11.25
}, {
  label: "11.375%",
  value: 11.375
}, {
  label: "11.500%",
  value: 11.5
}, {
  label: "11.625%",
  value: 11.625
}, {
  label: "11.750%",
  value: 11.75
}, {
  label: "11.875%",
  value: 11.8755
},
{
  label: "12.000%",
  value: 12
}, {
  label: "12.125%",
  value: 12.125
}, {
  label: "12.250%",
  value: 12.25
}, {
  label: "12.375%",
  value: 12.375
}, {
  label: "12.500%",
  value: 12.5
}, {
  label: "12.625%",
  value: 12.625
}, {
  label: "12.750%",
  value: 12.75
}, {
  label: "12.875%",
  value: 12.875
}, {
  label: "13.000%",
  value: 13
}, {
  label: "13.125%",
  value: 13.125
}, {
  label: "13.250%",
  value: 13.25
}, {
  label: "13.375%",
  value: 13.375
}, {
  label: "13.500%",
  value: 13.5
}, {
  label: "13.625%",
  value: 13.625
}, {
  label: "13.750%",
  value: 13.75
}, {
  label: "13.875%",
  value: 13.875
},
{
  label: "14.000%",
  value: 14
}, {
  label: "14.125%",
  value: 14.125
}, {
  label: "14.250%",
  value: 14.25
}, {
  label: "14.375%",
  value: 14.375
}, {
  label: "14.500%",
  value: 14.5
}, {
  label: "14.625%",
  value: 14.625
}, {
  label: "14.750%",
  value: 14.75
}, {
  label: "14.875%",
  value: 14.875
},
{
  label: "15.000%",
  value: 15
}
]


